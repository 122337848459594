.history-dialog td {
    padding: 0.25rem 1rem 0.25rem 0;
}

input#response-type-input {
    width: 200px;
}

input#description-input {
    width: 400px;
}

div.process-dialog {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 150px;
}

div.process-buttons {
    display:flex;
    flex-direction: row;
    justify-content: center;
    gap: 20px;
}