div.header {
    position: relative;
    font-size: 2em;
    font-weight: bold;
    padding: 0.5em 0.5em;
}

    div.header div.userinfo {
        position: absolute;
        position: absolute;
        top: 50%;
        right: 2em;
        -ms-transform: translateY(-50%);
        transform: translateY(-50%);
        font-size: 0.5em;
        font-weight: normal;
        display: flex;
        gap: 1em;
    }


    div.header img.logo {
        position: absolute;
        height: 1.5em;
        padding-right: inherit;
        position: absolute;
        top: 50%;
        right: 0;
        -ms-transform: translateY(-50%);
        transform: translateY(-50%);
    }

div.footer {
    position: relative;
    font-size: 0.8em;
    font-weight: normal;
    padding: 0.5em 0.5em;
}
