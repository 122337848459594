.ui-autocomplete {
    max-height: 50vh;
    overflow-y: auto;
    /* prevent horizontal scrollbar */
    overflow-x: hidden;
}

div#data-table {
    height: 100%;
    flex-grow: 1;
}

.grid-container {
    display: flex;
    flex-direction: column;
    background-color: #e5f4ff;
    padding: 1rem;
    margin: 0;
    height: 100%;
}

#maingrid-container {
    flex-grow: 1;
}

div.hover-button {
    margin: auto 0 auto 0.5em;
    cursor: pointer;
}

    div.hover-button img {
        width: 1.5rem;
        height: 1.5rem;
    }

.ag-row:not(.ag-row-hover) div.hover-button {
    visibility: hidden;
}


.wrap-cell {
    line-height: 1.5rem !important;
    padding-top: 0.5rem;
    padding-bottom: 0.5rem;
    word-break: normal !important;
}

.template-client {
    display: flex;
}

.template-office {
    display: flex;
}

.template-client a {
    color: #0026ff;
}

.template-office a {
    color: #0019b1;
}

.template-client .hover-button {
    background: transparent;
}

.template-office .hover-button {
    background: transparent;
}

.template-group-checkbox {
    padding:0.25em;
}

.template-grid-container .ag-row-even {
    background: #ffffff;
}

.template-grid-container .ag-row-odd {
    background: #dddddd;
}

.local-hover:not(:hover) img {
    visibility: hidden;
}

.local-hover img {
    margin-left: 0.5em;
    margin-right: 1em;
    cursor: pointer;
}

.ms-ChoiceField {
    margin: auto;
}