div.header-adac {
    background: #ffcc00;
    color: #000000;
}

.adac-outer-container {
    display: flex;
    flex-direction: column;
    background-color: #ffffff;
    padding: 0;
    margin: 0;
    height: 100%;
    overflow: hidden;
}