.ui-autocomplete {
    max-height: 50vh;
    overflow-y: auto;
    /* prevent horizontal scrollbar */
    overflow-x: hidden;
}

div#data-table {
    height: 100%;
    flex-grow: 1;
}

.grid-container {
    display: flex;
    flex-direction: column;
    background-color: #e5f4ff;
    padding: 1rem;
    margin: 0;
    height: 100%;
}

#maingrid-container {
    flex-grow: 1;
}

#main-content {
    margin: 1em;
}

div.version-deprecated {
    color: red;
}

div.version-old {
    color: darkred;
}

div.version-beta {
    color: blue;
}

div.version-latest {
    color: darkgreen;
}

div.version-recent {
    color: olive;
}
