.rotated-th {
    /**
	* Since the rotated text is taken out of the DOM flow (position: absolute), we
	* need to artificially consume vertical space so that the rotated headers
	* don't overlap with the content above the table.
	*/
    height: 220px;
    position: relative;
}
/**
* When an element is transform rotated, it still takes up the amount of space that
* it would have if not rotated. As such, I'm making the label "position: absolute"
* so that it doesn't take up any space ("absolute" takes it out of the DOM flow).
* Instead, I'm deferring the space allocation to the parent DIV.
*/
.rotated-th__label {
    bottom: 5px;
    left: 50%;
    position: absolute;
    transform: rotate( -45deg );
    transform-origin: center left;
    white-space: nowrap;
    font-weight: normal;
    margin-left: -7px;
}
