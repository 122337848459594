div.combinedLinkList {
    display: flex;
    flex-wrap: wrap;
    width: 100%;
    justify-content: center;
}

.combinedLinkList button {
    background: #ddf;
    width: 16em;
    font-size: large;
    margin: 0.5em;
}

    .combinedLinkList button:hover {
        background: #eef;
        border: solid black 1px;
    }
