div.header-patentsurveillance {
    background: #00435e;
    color: #ffffff;
    font-family: 'arnhem';
}

.patentsurveillance-outer-container {
    display: flex;
    flex-direction: column;
    background-color: #ffffff;
    padding: 0;
    margin: 0;
    height: 100%;
    overflow: hidden;
}

@font-face {
    font-family: 'arnhem';
    src: url('../../resources/fonts/arnhempro-semibold.ttf');
}

#settingsLink {
    cursor: pointer;
}

#logOutLink {
    cursor: pointer;
}