div.filter-control-panel {
    margin-bottom: 1em;
    display: flex;
    gap: 2em;
    justify-content: space-between;
}

div.filter-spacer {
    flex-grow: 1;
}

div.filter-control {
    margin: auto;
}

div#representative-filter-control {
    display: flex;
    visibility: hidden;
}

#column-selector-button {
    padding:0;
}

#column-selector-button span {
    margin:0;
}

.text-field-label {
    font-weight: normal;
    margin: auto 0.25rem auto 0;
}