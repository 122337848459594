html {
    background-color: #e5f4ff;
}

.outer-container {
    display: flex;
    flex-direction: column;
    background-color: #e5f4ff;
    padding: 0;
    margin: 0;
    height: 100%;
}

.inner-container {
    display: flex;
    flex-direction: column;
    background-color: #e5f4ff;
    padding: 0;
    margin: 0;
    height: 100%;
}

.main-content {
    height: 100%;
}

.lds-dual-ring {
    display: inline-block;
    width: 80px;
    height: 80px;
}

.lds-dual-ring:after {
    content: " ";
    display: block;
    width: 64px;
    height: 64px;
    margin: 8px;
    border-radius: 50%;
    border: 6px solid #fff;
    border-color: #666 transparent #666 transparent;
    animation: lds-dual-ring 1.2s linear infinite;
}

@keyframes lds-dual-ring {
    0% {
        transform: rotate(0deg);
    }

    100% {
        transform: rotate(360deg);
    }
}


.lds-ring {
    display: inline-block;
    position: relative;
    width: 30px;
    height: 30px;
}

.lds-ring div {
    box-sizing: border-box;
    display: block;
    position: absolute;
    width: 24px;
    height: 24px;
    margin: 3px;
    border: 3px solid #fff;
    border-radius: 50%;
    animation: lds-ring 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
    border-color: #fff transparent transparent transparent;
}

.lds-ring div:nth-child(1) {
    animation-delay: -0.45s;
}

.lds-ring div:nth-child(2) {
    animation-delay: -0.3s;
}

.lds-ring div:nth-child(3) {
    animation-delay: -0.15s;
}

@keyframes lds-ring {
    0% {
        transform: rotate(0deg);
    }

    100% {
        transform: rotate(360deg);
    }
}


.lds-miniring {
    display: inline-block;
    position: relative;
    width: 1em;
    height: 1em;
}

.lds-miniring div {
    box-sizing: border-box;
    display: block;
    position: absolute;
    width: 1em;
    height: 1em;
    margin: 2px;
    border: 2px solid #666;
    border-radius: 50%;
    animation: lds-miniring 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
    border-color: #666 transparent transparent transparent;
}

.lds-miniring div:nth-child(1) {
    animation-delay: -0.45s;
}

.lds-miniring div:nth-child(2) {
    animation-delay: -0.3s;
}

.lds-miniring div:nth-child(3) {
    animation-delay: -0.15s;
}

@keyframes lds-miniring {
    0% {
        transform: rotate(0deg);
    }

    100% {
        transform: rotate(360deg);
    }
}
