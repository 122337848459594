.patent-surveillance-grid {
    margin-top: 1em;
    display: grid;
    grid-template-columns: 1fr 8em 1.25fr 5em 3fr 2.5fr 1.5fr 6em 2fr;
    border-left: 1px solid #888;
    border-top: 1px solid #888;
}

.patent-surveillance-grid-header {
    background-color: #ccc;
    font-weight: bold;
    padding: 0.4em 0.4em 0.4em 0.4em;
    font-size: 0.8em;
    border-right: 1px solid #888;
    border-bottom: 1px solid #888;
}

.patent-surveillance-grid-oddcell {
    background-color: #ddf;
    padding: 0.4em 0.4em 0.4em 0.4em;
    font-size: 0.8em;
    border-right: 1px solid #888;
    border-bottom: 1px solid #888;
}

.patent-surveillance-grid-evencell {
    background-color: #eef;
    padding: 0.4em 0.4em 0.4em 0.4em;
    font-size: 0.8em;
    border-right: 1px solid #888;
    border-bottom: 1px solid #888;
}

.patent-surveillance-grid-oddcell textarea {
    background-color: transparent;
    border: none;
    outline: none;
    resize: none;
    margin: -2px;
}

.patent-surveillance-grid-evencell textarea {
    background-color: transparent;
    border: none;
    outline: none;
    resize: none;
    margin: -2px;
}

.patent-surveillance-grid-oddcell .activetextarea {
    background-color: #eef;
    outline: solid #bbf 1px;
    min-height: 20em;
    margin: 0;
}

.patent-surveillance-grid-evencell .activetextarea {
    background-color: #fff;
    outline: solid #ccf 1px;
    min-height: 20em;
    margin: 0;
}

#pubNumButtonH {
    background: #f99;
}

    #pubNumButtonH:hover {
        background: #fbb;
        border: solid black 1px;
}

#pubNumButtonHM {
    background: #ff9;
}

    #pubNumButtonHM:hover {
        background: #ffb;
        border: solid black 1px;
}

#pubNumButtonHML {
    background: #9f9;
}

    #pubNumButtonHML:hover {
        background: #bfb;
        border: solid black 1px;
    }


.chat-message {
    background: white;
    padding: 0.5em;
    margin-bottom: 0.5em;
}

.chat-message-regular {
    border: solid gray 1px;
}

.chat-message-high {
    border: solid red 1px;
}

.chat-message-medium {
    border: solid yellow 1px;
}

.chat-message-low {
    border: solid green 1px;
}
/**/

.chat-message-mine {
    margin-left: 20%;
}

.chat-message-notmine {
    margin-right: 20%;
}

.chat-header {
    display: flex;
    font-size: 0.85em;
}