div.client-selector {
    font-size: 1.2em;
    padding: 1rem;
}

div.client-selector input {
    margin:0 0.5rem;
}

.ui-autocomplete {
    max-height: 50vh;
    overflow-y: auto;

    /* prevent horizontal scrollbar */
    overflow-x: hidden;

    /* Without this the vertical scrollbar often appears when selecting/hovering over the last entry */
    padding: 1px;
}

div#clientListWrapper {
    margin-top: 2rem;
}

div#main-content {
    display: none;
}

div#greeting {
    margin-bottom: 1rem;
}

div#suggested-client-list {
    margin-top: 2rem;
}

div.clients-heading {
    font-size: 18px;
    text-decoration: underline;
    margin-top: 1rem;
}

td.checkbox-cell {
    min-width: 12em;
}

td.checkbox-cell div.ms-Checkbox {
    margin-top: 0.25em;
}

td i {
    font-size: 16px;
}

.client-suffix {
    color: red;
    margin-left: 1em;
    font-size: 0.85em;
}