.ui-autocomplete {
    max-height: 50vh;
    overflow-y: auto;
    /* prevent horizontal scrollbar */
    overflow-x: hidden;
}

div#data-table {
    height: 100%;
    flex-grow: 1;
}

.grid-container {
    display: flex;
    flex-direction: column;
    background-color: #e5f4ff;
    padding: 1rem;
    margin: 0;
    height: 100%;
}

#maingrid-container {
    flex-grow: 1;
}

div.hover-button {
    margin: auto 0 auto 0.5em;
    cursor: pointer;
}

    div.hover-button img {
        width: 1.5rem;
        height: 1.5rem;
    }

.ag-row:not(.ag-row-hover) div.hover-button {
    visibility: hidden;
}


div.proc-step-wrapper {
    display: flex;
}

    div.proc-step-wrapper div.hover-button {
        margin: 0 !important;
        display: flex;
    }

        div.proc-step-wrapper div.hover-button img {
            cursor: pointer;
        }


div.app-num-wrapper {
    display: flex;
}

div.app-status-wrapper {
    margin: 0.5em 0 0.5em 0;
    display: flex;
    flex-direction: column;
    white-space: normal;
    line-height: normal;
}

div.app-status-main {
    margin-left: 1em;
    text-indent: -1em;
}

div.app-status-extra {
    color: red;
    margin-top: 0.5em;
}

.wrap-cell {
    line-height: 1.5rem !important;
    padding-top: 0.5rem;
    padding-bottom: 0.5rem;
    word-break: normal !important;
}

span.dot {
    margin: 0.15em 0.3em 0 0;
    height: 0.75em;
    min-width: 0.75em;
    width: 0.75em;
    border-radius: 50%;
    display: inline-block;
}

span.dot-green {
    background-color: #090;
}

span.dot-yellow {
    background-color: #ee0;
}

span.dot-red {
    background-color: #900;
}

span.dot-grey {
    background-color: #999;
}

div.proc-step-highlight {
    color: red;
    font-weight: bold;
}

.breakdown-table {
    margin: 1em;
    padding: 1em;
    background: white;
    border: 1px solid black;
}
.breakdown-table thead {
    font-weight: bold;
}

.breakdown-table td {
    padding-left: 1em;
}
