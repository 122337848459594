div#filter-controls {
    display: flex;
    align-items: center;
    gap: 1em;
    margin-bottom: 1em;
}

.filter-dropdown {
    width: 200px;
}
